import React from "react";
import { WhiteBgGreyParticle } from "../../components/WhiteBgGreyParticle/WhiteBgGreyParticle";
import Meta from "../../components/Meta";
import "./index.scss";

function IndexPage(props) {
  return (
    <>
      <div className="faithlink-home">
        <Meta />
        <div className="container">
          {/* react insert image */}
          <img
            src="https://d1ye7xnoi5h0df.cloudfront.net/img/fulllifelogo.png"
            className="logo-center"
            alt="logo"
          />
        </div>
        <WhiteBgGreyParticle />
      </div>
    </>
  );
}

export default IndexPage;
