import React from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import "./NewSheepForm.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createSheep } from "../../util/db";
import SparkMD5 from "spark-md5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isValueInFirestoreUnique } from "../../util/util";
import sendData from "../../util/api";
import CircularProgress from "@material-ui/core/CircularProgress";

let newSheepSchema = yup.object().shape({
  firstName: yup.string().required("Este campo es obligatorio."),
  lastName: yup.string().required("Este campo es obligatorio."),
  email: yup.string().email().required("Este campo es obligatorio."),
  address: yup.string().required("Este campo es obligatorio."),
  telephone: yup.string().required("Este campo es obligatorio."),
  // birthDate: yup.string().required("Este campo es obligatorio."),                  // this is not working
  sex: yup.string().required("Este campo es obligatorio."),
  civilStatus: yup.string().required("Este campo es obligatorio."),
  howdidyouhearaboutus: yup.string().required("Este campo es obligatorio."),
});

export const NewSheepForm = (props) => {
  const [sex, setSex] = React.useState("");
  const [civilStatus, setCivilStatus] = React.useState("");
  const [birthDate, setBirthDate] = React.useState(dayjs());
  const [isLoading, setIsLoading] = React.useState(false);

  const history = useHistory();

  const handleSexChange = (event) => {
    setSex(event.target.value);
  };

  const handlecivilStatusChange = async (event) => {
    setCivilStatus(event.target.value);
  };

  const handlebirthDateChange = (newValue) => {
    setBirthDate(newValue);
  };

  const saveSheep = async (data) => {
    // check email and phone numbers are unique
    const { isUnique, emailSize, telephoneSize } =
      await isValueInFirestoreUnique(data);
    console.log("isUnique", isUnique, emailSize, telephoneSize);
    if (!isUnique) {
      if (emailSize) {
        toast.error("Este correo ya se encuentra en uso", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      if (telephoneSize) {
        toast.error("Este teléphono ya se encuentra registrado", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }

      return;
    }

    //generate unique hash to const variable using the current date and data.email
    setIsLoading(true);
    const qr = SparkMD5.hash(dayjs().format("YYYYMMDDHHmmss") + data.email);

    const dataToSave = {
      ...data,
      qr,
      createdate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      birthDate: birthDate.format("YYYY-MM-DD"),
    };

    createSheep(dataToSave, setIsLoading);

    const dataToSend = {
      phone_number: dataToSave.telephone,
      name: dataToSave.firstName,
      sex: dataToSave.sex,
      qr: dataToSave.qr,
    };
      await sendData(dataToSend);
    //in the future show confimation message and then redirect to qr page

    history.push("/qr/" + qr);
  };

  return (
    <Container component="main" maxWidth="xs" className="new-sheep-form">
      <CssBaseline />
      <div className="paper">
        <ToastContainer />
        <Typography component="h1" variant="h5">
          Oveja nueva
        </Typography>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            address: "",
            telephone: "",
            birthDate: "",
            sex: "",
            civilStatus: "",
            howdidyouhearaboutus: "",
          }}
          validationSchema={newSheepSchema}
          onSubmit={(values) => {
            console.log(values);
            saveSheep(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className="form">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errors.firstName && touched.firstName}
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="firstName"
                    label="Nombre"
                    autoFocus
                    helpertext={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errors.lastName && touched.lastName}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="lastName"
                    label="Apellidos"
                    name="lastName"
                    autoComplete="lname"
                    helpertext={
                      errors.lastName && touched.lastName
                        ? errors.lastName
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.email && touched.email}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    helpertext={
                      errors.email && touched.email ? errors.email : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.address && touched.address}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    name="address"
                    label="Dirección"
                    type="address"
                    id="address"
                    autoComplete="current-address"
                    helpertext={
                      errors.address && touched.address ? errors.address : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={errors.telephone && touched.telephone}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    name="telephone"
                    label="Celular"
                    type="number"
                    id="telephone"
                    autoComplete="current-telephone"
                    helpertext={
                      errors.telephone && touched.telephone
                        ? errors.telephone
                        : null
                    }
                  />
                </Grid>{" "}
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      fullWidth
                      label="Fecha de Nacimiento"
                      inputFormat="MM/DD/YYYY"
                      value={birthDate}
                      id="birthDate"
                      onChange={handlebirthDateChange}
                      helpertext={
                        errors.birthDate && touched.birthDate
                          ? errors.birthDate
                          : null
                      }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">Género</InputLabel>
                  <Select
                    error={errors.sex && touched.sex}
                    name="sex"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="sex"
                    value={sex}
                    label="Age"
                    onChange={(e) => {
                      handleSexChange(e);
                      handleChange(e);
                    }}
                    helpertext={errors.sex && touched.sex ? errors.sex : null}
                  >
                    <MenuItem value={""}>Selecciona una opcion</MenuItem>
                    <MenuItem value={"H"}>Hombre</MenuItem>
                    <MenuItem value={"M"}>Mujer</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="demo-simple-select-label">
                    Estado Civil
                  </InputLabel>
                  <Select
                    error={errors.civilStatus && touched.civilStatus}
                    name="civilStatus"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="civilStatus"
                    value={civilStatus}
                    label="Age"
                    onChange={(e) => {
                      handlecivilStatusChange(e);
                      handleChange(e);
                    }}
                    helpertext={
                      errors.civilStatus && touched.civilStatus
                        ? errors.civilStatus
                        : null
                    }
                  >
                    <MenuItem value={""}>Selecciona una opcion</MenuItem>
                    <MenuItem value={"S"}>Soltero</MenuItem>
                    <MenuItem value={"C"}>Casado</MenuItem>
                    <MenuItem value={"D"}>Divorciado</MenuItem>
                    <MenuItem value={"V"}>Viudo</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={
                      errors.howdidyouhearaboutus &&
                      touched.howdidyouhearaboutus
                    }
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={5}
                    maxRows={7}
                    onChange={handleChange}
                    name="howdidyouhearaboutus"
                    label="Como escuchaste de nosotros?"
                    type="howdidyouhearaboutus"
                    id="howdidyouhearaboutus"
                    autoComplete="current-howdidyouhearaboutus"
                    helpertext={
                      errors.howdidyouhearaboutus &&
                      touched.howdidyouhearaboutus
                        ? errors.howdidyouhearaboutus
                        : null
                    }
                  />
                </Grid>
                {/* end */}
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Enviar"}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
