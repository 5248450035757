import React, { useState } from "react";
import Meta from "../../components/Meta";
import { QrReader } from 'react-qr-reader';


import "./QRReader.scss";
import { getSheepByQR } from "../../util/db";
 
function QRReader(props) {
  const [data, setData] = useState('No result');

  return (
    <>
      <div className="qr-reader">
        <Meta />
        <h1>Lector QR</h1>

        <QrReader className="qr-reader__reader" constraints={{ facingMode: 'environment' }}
        onResult={(result, error) => {
          if (!!result) {
            setData(result?.text);
            const sheep = getSheepByQR(result?.text);
            console.log(sheep);

          }

          if (!!error) {
            // console.info(error);
          }
        }}
        style={{ width: '100%' }}
      />
      <p>{data}</p>
       
      </div>
    </>
  );
}

export default QRReader;
