import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Verify.scss";
import Meta from "../../components/Meta";
import { markSheep, useQRtwo } from "../../util/db";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import dayjs from "dayjs";

const Verify = () => {
  const { qr } = useParams();
  const [bibleRedeemed, setBibleRedeemed] = useState(false);
  const [coffeeRedeemed, setCoffeeRedeemed] = useState(false);
  const [theData, setTheData] = useState({});
  const { data: items, status: itemsStatus, error: itemsError } = useQRtwo(qr);
  const itemsAreEmpty = !items || items.length === 0;

  useEffect(() => {
    //validate that it has not yet been redeemed
    if (itemsStatus !== "loading" && items && items.length > 0) {
      console.log("items", items);
      console.log("itemsStatus", itemsStatus);
      console.log("itemsError", itemsError);

      setTheData(items[0]);
      console.log("item  0", items[0]);
      console.log("theData", items[0].coffeeRedeemed);

      //if items[0].bibleRedeemed or undefined  or null

      if (
        items[0].bibleRedeemed !== undefined &&
        items[0].bibleRedeemed !== null &&
        items[0].bibleRedeemed !== ""
      ) {
        setBibleRedeemed(true);
      }
      if (
        items[0].coffeeRedeemed !== undefined &&
        items[0].coffeeRedeemed != null &&
        items[0].coffeeRedeemed !== ""
      ) {
        setCoffeeRedeemed(true);
      }
    }
  }, [itemsStatus]);

  const now = dayjs();

  const eightDaysFromNow = now.add(6, "day");

  //function that alerts message
  const markAsRedeemed = async (field) => {
    const errorMessage = `Cafê solo puede ser redimido el ${eightDaysFromNow.format(
      "MMMM D, YYYY"
    )}`;

    if (field === "coffeeRedeemed") {
      if (now.isBefore(eightDaysFromNow)) {
        return alert(errorMessage);
      }
    }

    const result = await markSheep(field, theData.id);
    if (result.success) {
      alert("Persona marcada con éxito");
    } else if (result.error) {
      alert(result.error);
    }
  };

  return (
    <>
      <Meta />

      {(itemsStatus === "loading" || itemsAreEmpty) && (
        <Box py={5} px={3} align="center">
          {itemsStatus === "loading" && <CircularProgress size={32} />}

          {itemsStatus !== "loading" && itemsAreEmpty && (
            <>
              Codigo no encontrado, por favor acercate al modulo de Life Connect
              para registrate manualmente
            </>
          )}
        </Box>
      )}

      {itemsStatus !== "loading" && items && items.length > 0 && (
        <div className="verify-view ">
          <h1>Verificar</h1>
          {/* if items is undefined show error message */}
          {itemsError && (
            <Box mb={3}>
              <Alert severity="error">{itemsError.message}</Alert>
            </Box>
          )}
          {/* if items is not undefined show the data */}
          {theData && (
            <Box mb={3}>
              <Alert severity="success">Persona encontrada con éxito</Alert>
            </Box>
          )}
          {/* show data in an organized maner */}
          {theData && (
            <div className="verify-view__data">
              <p>
                Nombre: {theData.firstName} {theData.lastName}
              </p>
              <p>Dirección: {theData.address}</p>
              <p>Email: {theData.email}</p>
              <p>Celular: {theData.telephone}</p>
              {/* if bibleRedeemed is  set  show it */}
              {bibleRedeemed && (
                <p>
                  Biblia redimida el {dayjs(theData.bibleRedeemed).format('YYYY-MM-DD')}
                </p>
              )}
              {/* if coffeeRedeemed is  set  show it */}
              {coffeeRedeemed && (
                <p>
                  Cafe redimido el {dayjs(theData.coffeeRedeemed).format('YYYY-MM-DD')}
                </p>
              )}
            </div>
          )}
          {/* show two horizontal buttons*/}
          <div className="verify-view__buttons">
            <Button
              variant="contained"
              onClick={() => markAsRedeemed("bibleRedeemed")}
              disabled={bibleRedeemed}
            >
              Marcar Biblia
            </Button>
          </div>
          <div className="verify-view__buttons">
            {/* <Button variant="outlined" onClick={() => markAsRedeemed(`Cafê solo puede ser redimido el ${eightDaysFromNow.format('MMMM D, YYYY')}`)}>Marcar Cafe</Button> */}
            <Button
              variant="outlined"
              onClick={() => markAsRedeemed("coffeeRedeemed")}
              disabled={coffeeRedeemed}
            >
              Marcar Cafe
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default Verify;
