import { firebaseApp } from "./firebase";
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';

const db = getFirestore(firebaseApp);


// Save stream key and embed code to Firestore
export const saveStreamData = async (streamKey, embedCode) => {
  try {
    await setDoc(doc(db, 'livestream', 'streamData'), {
      streamKey,
      embedCode,
    });
    console.log('Data successfully saved');
  } catch (error) {
    console.error('Error saving document: ', error);
  }
};

// Query the stream key and embed code from Firestore
export const getStreamData = async () => {
  try {
    const docRef = doc(db, 'livestream', 'streamData');
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data(); // Returns {streamKey, embedCode}
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
    return null;
  }
};
