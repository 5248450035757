import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import { getStreamData, saveStreamData } from '../util/firebaseLivestreamService';
import Meta from '../components/Meta';

const LiveStream = () => {
  const [streamKey, setStreamKey] = useState('');
  const [embedCode, setEmbedCode] = useState('');
  const [errors, setErrors] = useState({ streamKey: false, embedCode: false });
  const [isLoading, setIsLoading] = useState(true); // Loader for initial data fetching
  const [isSavingStreamKey, setIsSavingStreamKey] = useState(false); // Loader for stream key save
  const [isSavingEmbedCode, setIsSavingEmbedCode] = useState(false); // Loader for embed code save

  useEffect(() => {
    const fetchStreamData = async () => {
      try {
        const data = await getStreamData();
        if (data) {
          setStreamKey(data.streamKey || '');
          setEmbedCode(data.embedCode || '');
        }
      } catch (error) {
        console.error("Error fetching stream data", error);
      } finally {
        setIsLoading(false); // Stop loader once data is fetched
      }
    };

    fetchStreamData();
  }, []);

  const handleStreamKeySave = async () => {
    if (streamKey.trim() === '') {
      setErrors((prevState) => ({ ...prevState, streamKey: true }));
      return;
    }
    setErrors((prevState) => ({ ...prevState, streamKey: false }));
    setIsSavingStreamKey(true); // Start saving loader

    try {
      await saveStreamData(streamKey, embedCode);
    } catch (error) {
      console.error("Error saving stream key", error);
    } finally {
      setIsSavingStreamKey(false); // Stop saving loader
    }
  };

  const handleEmbedCodeSave = async () => {
    if (embedCode.trim() === '') {
      setErrors((prevState) => ({ ...prevState, embedCode: true }));
      return;
    }
    setErrors((prevState) => ({ ...prevState, embedCode: false }));
    setIsSavingEmbedCode(true); // Start saving loader

    try {
      await saveStreamData(streamKey, embedCode);
    } catch (error) {
      console.error("Error saving embed code", error);
    } finally {
      setIsSavingEmbedCode(false); // Stop saving loader
    }
  };

  return (
    <>
      <Meta title="Live stream config" />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        padding={2}
      >
        <Typography variant="h4" gutterBottom>
          Live Stream Settings
        </Typography>

        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Stream Key Section */}
            <Box marginBottom={3} width="100%" maxWidth="400px">
              <TextField
                fullWidth
                label="Adornate Stream Key"
                variant="outlined"
                value={streamKey}
                onChange={(e) => setStreamKey(e.target.value)}
                error={errors.streamKey}
                helperText={errors.streamKey ? 'Stream Key is required' : ''}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleStreamKeySave}
                style={{ marginTop: '16px' }}
                disabled={isSavingStreamKey} // Disable button while saving
              >
                {isSavingStreamKey ? <CircularProgress size={24} /> : 'Save Stream Key'}
              </Button>
            </Box>

            {/* Embed Code Section */}
            <Box marginBottom={3} width="100%" maxWidth="400px">
              <TextField
                fullWidth
                label="Embed Code"
                variant="outlined"
                multiline
                rows={6}
                value={embedCode}
                onChange={(e) => setEmbedCode(e.target.value)}
                error={errors.embedCode}
                helperText={errors.embedCode ? 'Embed Code is required' : ''}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleEmbedCodeSave}
                style={{ marginTop: '16px' }}
                disabled={isSavingEmbedCode} // Disable button while saving
              >
                {isSavingEmbedCode ? <CircularProgress size={24} /> : 'Save Embed Code'}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default LiveStream;
