import React from "react";
import { useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import { QRCodeCanvas } from "qrcode.react";

import "./QRViewer.scss";
import { getSheepByQR } from "../../util/db";

function QRViewer(props) {
  const { qr } = useParams();
  //TODO: validate the QR code exist on the database
  // add env variables for url
  const fullUrl = 'https://main.d9723y9v9tz0h.amplifyapp.com/verify/' + qr;

 // const fullUrl = 'https://iglesiafulllife.click/verify/' + qr;
  const sheep = getSheepByQR(qr);
  console.log(sheep);

  console.log(qr);
  const qrcode = (
    <QRCodeCanvas
      id="qrCode"
      value={fullUrl}
      size={200}
      level={"L"}
      marginSize={4}
    />
  );
  return (
    <>
      <div className="qr-viewer">
        <Meta />
        <h1>Muchas gracias</h1>
        <p>
        Presenta este código QR en la estación de Bienvenida para reclamar un regalo especial preparado para ti, hoy y la próxima semana.
                </p>
        <div>{qrcode}</div>
      </div>
    </>
  );
}

export default QRViewer;
