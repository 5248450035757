import React from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { exportToCSV } from "../../util/csv-export";

const NameSundayExport = () => {
  // Function to calculate the date of the last Sunday
  const getLastSunday = (weeksAgo = 0) => {
    let date = new Date();
    let day = date.getDay();
    let sunday = new Date(date.setDate(date.getDate() - day - weeksAgo * 7));
    return sunday;
  };

  // Calculate the dates of the current and previous three Sundays
  const sundays = [0, 1, 2].map(getLastSunday);

  // Function to handle button click
  const handleClick = (date) => {
    exportToCSV(date);
  };

  // Function to format the date
  const formatDate = (date) => {
    return date.toLocaleDateString();
  };

  return (
    <Box>
      {sundays.map((sunday, index) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClick(sunday)}
          style={{ marginRight: "10px" }}
          key={index}
        >
          {formatDate(sunday)}
        </Button>
      ))}
    </Box>
  );
};

export default NameSundayExport;
