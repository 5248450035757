import React from "react";
import Meta from "../../components/Meta";
import { NewSheepForm } from "../../components/NewSheepForm/NewSheepForm";
import "./NewSheep.scss";

function NewSheep(props) {
  return (
    <>
      <div className="new-sheep">
        <Meta />
        <NewSheepForm />
      </div>
    </>
  );
}

export default NewSheep;
