import React from "react";
import "./WhiteBgGreyParticle.scss";

export const WhiteBgGreyParticle = () => {
  return (
    <div className="background-">
      <div className="white">
        <div className="squares">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>
      </div>
      <div className="grey"></div>
    </div>
  );
};
