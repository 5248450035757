import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardItems from "./DashboardItems";
import { Link } from "../util/router";
import { useAuth } from "../util/auth";
import Table from "./sheepTable/SheepTable";
import NameSundayExport from "./NameSundayExport/NameSundayExport";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));
const cardStyle = {
  marginTop: "20px",
};

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Box mx="auto" mb={4}>
          {auth.user.email !== "monorph@gmail.com" && <Table />}
        </Box>

        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={6}>
            <DashboardItems />
            <Card style={cardStyle}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography variant="h6" paragraph={true}>
                    <strong>Exportar nombres domingos</strong>
                  </Typography>

                  <Box mt={3}>
                    <NameSundayExport />
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography variant="h6" paragraph={true}>
                    <strong>Usuario Actual</strong>
                  </Typography>

                  <Box mt={3}>
                    <Typography component="div">
                      <div>
                        Haz ingresado como <strong>{auth.user.email}</strong>.
                      </div>

                      {auth.user.stripeSubscriptionId && (
                        <>
                          <div>
                            You are subscribed to the{" "}
                            <strong>{auth.user.planId} plan</strong>.
                          </div>
                          <div>
                            Your plan status is{" "}
                            <strong>
                              {auth.user.stripeSubscriptionStatus}
                            </strong>
                            .
                          </div>
                        </>
                      )}

                      <div>
                        Puedes cambiar la información de tu cuenta{` `}
                        {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                        en{` `}
                        <LinkMui component={Link} to="/settings/general">
                          <strong>configuración</strong>
                        </LinkMui>
                        .
                      </div>
                      {/* 
                      {!auth.user.stripeSubscriptionId && (
                        <div>
                          You can signup for a plan in{" "}
                          <LinkMui component={Link} to="/pricing">
                            <strong>pricing</strong>
                          </LinkMui>
                          .
                        </div>
                      )} */}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default DashboardSection;
