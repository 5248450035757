export const coulums = [
  {
    accessorKey: "createdate",
    header: "Fecha Creación",
    size: 220,
  },

  {
    accessorKey: "firstName",
    header: "Nombre",
    size: 120,
  },
  {
    accessorKey: "lastName",
    header: "Apellido",
    size: 120,
  },
  {
    accessorKey: "birthDate",
    header: "Edad",
    size: 12,
  },
  {
    accessorKey: "address",
    header: "Dirección",
    size: 300,
  },
  {
    accessorKey: "email",
    header: "Email",
  },
  {
    accessorKey: "howdidyouhearaboutus",
    header: "Como escuchaste",
    size: 220,
  },
  {
    accessorKey: "sex",
    header: "Genero",
    size: 220,
  },
  {
    accessorKey: "telephone",
    header: "telephone",
    size: 220,
  },
  {
    accessorKey: "bibleRedeemed",
    header: "Biblia Entregada",
    size: 220,
  },
  {
    accessorKey: "coffeeRedeemed",
    header: "Café Redimido",
    size: 220,
  },
];

export const csvOption = (columns) => {
  return {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
};
