const sendData = async (data) => {
    debugger;
  const lastLetter = data.sex.toLowerCase() === "h" ? "o" : "a";
  const phone = data.phone_number.toString().replace(/ /g, "");

  const url = process.env.REACT_APP_SMS_API_URL;
  const dataToSend = {
    api_key: "asdfhj29foihawf",
    phone_number: `${phone}`,
    text: `${data.name}, bienvenid${lastLetter} a Full Life! Este es tu codigo QR: https://iglesiafulllife.click/qr/${data.qr}  Estamos muy felices de haberte recibido, estamos orando por tus peticiones y para que tu amistad con Jesús crezca, escríbenos cualquier consulta, esperamos verte nuevamente este fin de semana, Dios tiene más para ti, bendiciones!!!`,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    console.log(result);
  } catch (error) {
    console.error("Error calling API:", error.message);
  }
};

export default sendData;
